var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pt-4" },
    [
      _vm.loading
        ? _c(
            "div",
            { staticClass: "mt-1 d-flex justify-content-center loader" },
            [_c("PortletLoader", { attrs: { inline: true } })],
            1
          )
        : [
            _vm.documents.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.documents, function (document, idx) {
                    return _c(
                      "b-dropdown",
                      {
                        key: `document_${document.studentProfileDocumentId}_${idx}`,
                        class: !_vm.wideMode
                          ? "student-doc w-100 btn-block"
                          : "student-doc",
                        attrs: {
                          "toggle-class": "btn-clean btn-clean-primary",
                          variant: "none",
                          "no-caret": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function () {
                                return [
                                  _c("SVGIcon", {
                                    staticClass: "kt-svg-icon pull-right",
                                    attrs: { name: document.type },
                                  }),
                                  _c("span", { staticClass: "pull-left" }, [
                                    _vm._v(
                                      " " + _vm._s(document.fileTitle) + " "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              variant: "none",
                              "button-class": "dropdown-item",
                              target: "_blank",
                              href: _vm.downloadDocument(document),
                            },
                          },
                          [
                            _c("i", { staticClass: "la la-download" }),
                            _vm._v(" Download "),
                          ]
                        ),
                        _vm.$_userMixins_isSchoolUser &&
                        !_vm.$store.state.forceStudentView
                          ? _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  variant: "danger",
                                  "button-class": "dropdown-item",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteDocument(document)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "la la-trash" }),
                                _vm._v(" Delete "),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.$_userMixins_isSchoolUser &&
            !_vm.$store.state.forceStudentView &&
            !_vm.addingDocument
              ? _c("div", { class: _vm.documents.length ? "pt-4" : "pt-2" }, [
                  _c(
                    "a",
                    {
                      staticClass: "kt-font-lg kt-font-bolder kt-font-success",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.addingDocument = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _vm._v(" Upload New Document "),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "pt-2" }, [
                  _c("div", { staticClass: "py-1 pb-3" }, [
                    _c("input", {
                      staticStyle: { display: "none" },
                      attrs: {
                        id: "file-input",
                        type: "file",
                        name: "file",
                        accept: ".pdf,.xls,.xlsx",
                      },
                      on: { change: _vm.onFileBrowseSelect },
                    }),
                    _c("label", [_vm._v("Document File")]),
                    _c(
                      "div",
                      {
                        staticClass: "input-group",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.triggerFileBrowser.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("input", {
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeholder: _vm.form.file
                              ? `${_vm.form.file.name}`
                              : ".pdf, .xls, or .xlsx",
                            readonly: "readonly",
                          },
                          on: { focus: _vm.focusInput, blur: _vm.unFocusInput },
                        }),
                        _vm._m(0),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "pb-3" }, [
                    _c("label", [_vm._v("Document name")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.documentName,
                          expression: "form.documentName",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "Document name" },
                      domProps: { value: _vm.form.documentName },
                      on: {
                        focus: _vm.focusInput,
                        blur: _vm.unFocusInput,
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.form,
                            "documentName",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "kt-form__actions pt-3 pb-2" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-lg-6" }),
                      _c("div", { staticClass: "col-lg-6 kt-align-right" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { disabled: _vm.uploadDisabled },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.uploadDocument()
                              },
                            },
                          },
                          [_vm._v(" Upload Document ")]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "input-group-append" }, [
      _c("span", { staticClass: "input-group-text" }, [_vm._v(" Browse ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }