<!-- eslint-disable vue/no-v-html -->
<template>
<div v-if="student">
    <div class="kt-portlet">
        <div :role="clickable ? 'button': ''" :class="['kt-portlet__body kt-ribbon kt-ribbon--clip kt-ribbon--right', wideMode ? 'p-4 my-3 wider' : '']">
            <template v-if="topBadge && showTopBadge">
                <!-- Ribbon Content -->
                <span class="kt-top-badge">
                    <AchievementBadge
                        :badge="topBadge"
                        :earned="true"
                        :is-portlet-ribbon="true"
                    />
                </span>
                <div :class="`kt-ribbon__target pl-0 ${topBadge.badgeColor} ${wideMode ? 'wide' : 'narrow'}`">
                    <span class="kt-ribbon__inner" />
                    <div class="ribbon-container">
                        <span :class="`badge-title ${wideMode ? 'wide' : 'narrow'}`">
                            {{ topBadge.badgeTitle }}
                        </span>
                    </div>
                </div>
            </template>

            <!-- Student Content -->
            <div :class="`kt-widget kt-widget--user-profile-2 kt-widget--${deviceType} kt-widget--${displayWide ? 'wide' : 'narrow'}`">
                <div class="row">
                    <div :class="[ wideMode ? 'col-6' : 'col-12']">
                        <div
                            v-if="!thumbnail"
                            class="kt-widget__head mt-0 pt-0"
                        >
                            <StudentPortfolioAvatar
                                :student="student"
                                @click.native.stop.prevent="toggleThumbnail"
                            />
                            <div class="kt-widget__info">
                                <a
                                    v-if="(encryptionEnabled && $_userMixins_isSchoolUser) || isHomeUser"
                                    href="#"
                                    class="kt-widget__username d-block w-100"
                                    @click.stop.prevent="$_panelMixins_openPanelForStudent(student)"
                                >
                                    {{ student.maskedStudentName }}
                                </a>
                                <a
                                    v-else
                                    href="#"
                                    class="kt-widget__username d-block w-100"
                                    @click.stop.prevent="$_panelMixins_openPanelForStudent(student)"
                                >
                                    {{ student.lastName }}, {{ student.firstName }}
                                </a>
                                <span class="kt-widget__desc">
                                    {{ $_utilMixins_format_grade_level(student.gradeLevel) }}
                                    <span class="pull-right">
                                        {{ student.homeRoom }}
                                    </span>
                                </span>
                                <div
                                    v-if="!isHomeUser"
                                    class="badge-container"
                                >
                                    <template v-if="administrativeBadges.length">
                                        <span class="text-muted">
                                            Badges:
                                        </span>
                                        <span
                                            v-for="(item) in administrativeBadges"
                                            :key="`badge_${item.badge.badgeId}`"
                                            v-b-tooltip.hover
                                            role="button"
                                            :title="`${item.count}x ${item.badge.badgeTitle}`"
                                            class="mx-1"
                                            @click.stop.prevent="showStudentBadgesRoute"
                                        >
                                            {{ item.badge.badgeIcon }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div
                            v-else
                            role="button"
                        >
                            <div class="w-100 text-center">
                                <b-img
                                    v-if="student.pictureGuid"
                                    :src="`https://lilo-app.s3.amazonaws.com/${student.pictureGuid}.jpg`"
                                    fluid-grow
                                    class="big-picture"
                                    @click.stop.prevent="thumbnail = !thumbnail"
                                />
                            </div>
                            <div class="kt-widget__head mt-4">
                                <div class="kt-widget__info">
                                    <span v-if="encryptionEnabled" class="kt-widget__username">
                                        {{ student.maskedStudentName }}
                                    </span>
                                    <span v-else class="kt-widget__username">
                                        {{ student.lastName }}, {{ student.firstName }}
                                    </span>
                                    <span class="kt-widget__desc">
                                        {{ $_utilMixins_format_grade_level(student.gradeLevel) }}
                                        <span class="pull-right">
                                            {{ student.homeRoom }}
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="[ wideMode ? 'col-6' : 'd-none' ]">
                        <div v-if="!isHomeUser" class="text-center pt-4">
                            <button
                                v-if="encryptionEnabled"
                                type="button"
                                style="min-width: 160px;"
                                class="btn btn-label-brand btn-bold btn-upper mt-4"
                                @click="decrypt"
                            >
                                Unlock PII
                            </button>
                        </div>
                    </div>
                </div>

                <!-- collapsable pii content -->
                <div class="kt-widget__body">
                    <div
                        v-if="!encryptionEnabled || isHomeUser"
                        class="kt-widget__item"
                        style="font-size: 1.1rem;"
                    >
                        <!-- Address -->
                        <template v-if="!isHomeUser">
                            <div
                                v-if="student.address && student.street"
                                class="kt-widget__contact pt-4 pb-3"
                            >
                                <span class="kt-widget__label">
                                    Address
                                </span>
                                <span class="kt-widget__data text-right">
                                    {{ student.street }} <br>
                                    {{ student.city }}, {{ student.state }} {{ student.zip }}
                                </span>
                            </div>
                            <!-- Student Id -->
                            <div class="kt-widget__contact pb-3">
                                <span class="kt-widget__label">Student Id</span>
                                <span class="kt-widget__data">
                                    {{ student.extStudentId.substring(0, 3) }}-{{ student.extStudentId.substring(3, 6) }}-{{ student.extStudentId.substring(6, 9) }}
                                </span>
                            </div>
                            <!-- Google Email -->
                            <div
                                v-if="student.googleEmail"
                                class="kt-widget__contact pb-3"
                            >
                                <span class="kt-widget__label">Google</span>
                                <span
                                    class="kt-widget__data text-right"
                                    v-html="`${$_utilMixins_format_email(student.googleEmail)}`"
                                />
                            </div>
                            <!-- School Email -->
                            <div
                                v-if="student.schoolEmail"
                                class="kt-widget__contact pb-3"
                            >
                                <span class="kt-widget__label">Email</span>
                                <span
                                    class="kt-widget__data text-right"
                                    v-html="`${$_utilMixins_format_email(student.schoolEmail)}`"
                                />
                            </div>
                        </template>

                        <div class="kt-separator kt-separator--space-lg kt-separator--border-dashed" />
                        <StudentExternalDocuments
                            :external-documents="externalDocuments"
                            :wide-mode="wideMode"
                        />
                    </div>
                </div>
                <div
                    v-if="!wideMode && !isHomeUser"
                    class="kt-widget__footer"
                >
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        class="btn btn-label-primary btn-lg kt-font-lg btn-upper"
                        @click="decrypt"
                    >
                        UNLOCK PII
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div
        v-if="showUploader"
        class="kt-portlet"
    >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    Documents
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <StudentProfileDocuments
                :wide-mode="wideMode"
                :set-upload-focused="setUploadFocused"
            />
        </div>
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import AchievementBadge from './AchievementBadge.vue';
import StudentPortfolioAvatar from './StudentPortfolioAvatar.vue';
import StudentProfileDocuments from './StudentProfileDocuments.vue';
import studentMixins from '../store/mixins/studentMixins';
import userMixins from '../store/mixins/userMixins';
import utilMixins from '../store/mixins/utilMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import panelMixins from '../store/mixins/panelMixins';
import Types from '../store/Types';
import StudentExternalDocuments from './StudentExternalDocuments.vue';

export default Vue.extend({
    name: 'StudentHeaderPortlet',
    components: {
        AchievementBadge,
        StudentExternalDocuments,
        StudentProfileDocuments,
        StudentPortfolioAvatar,
    },
    mixins: [
        userMixins,
        studentMixins,
        utilMixins,
        panelMixins,
        badgeMixins,
    ],
    props: {
        displayWide: {
            type: Boolean,
            default: false,
        },
        showTopBadge: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            hovered: false,
            thumbnail: false,
            showUploader: false,
            uploadFocused: false,
        };
    },
    computed: {
        isHomeUser() {
            const isHomeUser = this.$_userMixins_isHomeUser;
            return this.forceStudentView ? true : isHomeUser;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        clickable() {
            const { isPortletRibbon, isHomeUser } = this;
            if (this.$route.name === 'StudentBadges' && isPortletRibbon && !isHomeUser) return false;
            return true;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled;
        },
        serverSideEncryptionEnabled() {
            return this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        wideMode() {
            if (this.displayWide) {
                if (this.deviceType === 'mobile') return false;
                return true;
            }
            return this.deviceType === 'tablet';
        },
        student() {
            const student = this.$_studentMixins_getStudentFromRoute;
            if (!student) return null;
            student.address = student.street ? `${student.street} \n${student.city}, ${student.state} ${student.zip}` : null;
            return student;
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        hasBadge() {
            return Boolean(this.studentBadge);
        },
        schoolBadges() {
            return this.$store.state.database.badges;
        },
        topBadge() {
            return this.$_badgeMixins_getAchievementLevelFromRoute;
        },
        administrativeBadges() {
            const { uniqueEarnedBadges } = this;
            return uniqueEarnedBadges.filter((b) => b.badge.badgeType == 'Administrative Badge');
        },
        uniqueEarnedBadges() {
            return this.$_badgeMixins_getUniqueEarnedBadgesFromRoute;
        },
        earnedBadges() {
            return this.$_badgeMixins_getEarnedBadgesFromRoute;
        },
        studentPdfReports() {
            const { student } = this;
            if (!student) return [];
            const { studentEnrollmentId } = student;
            return this.$store.state.database.studentPdfReports.filter((r) => r.studentEnrollmentId === studentEnrollmentId);
        },
        schoolYear() {
            return this.$store.state.user.school.schoolYear;
        },
        schoolTerm() {
            return parseInt(this.$store.state.user.school.schoolTerm, 10);
        },
        markingPeriod() {
            const { schoolTermMarkingPeriodId } = this.$store.state.settings;
            const { markingPeriods } = this.$store.state.database;
            return markingPeriods.find((mp) => mp.schoolTermMarkingPeriodId === schoolTermMarkingPeriodId) || null;
        },
        reportCard() {
            const { schoolYear, schoolTerm } = this;
            const mp = this.markingPeriod;
            return this.studentPdfReports
                .find((r) => r.pdfType == 'Student Report Cards'
                    && r.extMarkingPeriod == mp.markingPeriod
                    && r.extSchoolYear == schoolYear
                    && r.extSchoolTerm == schoolTerm
                    && r.showOnPortfolio == true);
        },
        transcript() {
            const { schoolYear, schoolTerm } = this;
            return this.studentPdfReports
                .find((r) => r.pdfType == 'Student Transcripts'
                    && r.extSchoolYear == schoolYear
                    && r.extSchoolTerm == schoolTerm
                    && r.showOnPortfolio == true);
        },
        schedule() {
            const { schoolYear, schoolTerm } = this;
            return this.studentPdfReports
                .find((r) => r.pdfType == 'Student Schedules'
                    && r.extSchoolYear == schoolYear
                    && r.extSchoolTerm == schoolTerm
                    && r.showOnPortfolio == true);
        },
        examSchedule() {
            const { schoolYear, schoolTerm } = this;
            return this.studentPdfReports
                .find((r) => r.pdfType == 'Student Exam Invitations'
                    && r.extSchoolYear == schoolYear
                    && r.extSchoolTerm == schoolTerm
                    && r.showOnPortfolio == true);
        },
        externalDocuments() {
            const { reportCard, transcript } = this;
            const { schedule, examSchedule } = this;
            const items = [];
            const { $route, student } = this;
            const { name, params } = $route;
            if (reportCard) {
                const query = {
                    lightbox: true,
                    officialPdfUploadId: reportCard.officialPdfUploadId,
                    studentEnrollmentId: student.studentEnrollmentId,
                };
                const className = 'nav-link Blue';
                items.push({
                    text: `MP ${reportCard.extMarkingPeriod} Report Card`,
                    routerLink: {
                        name,
                        params,
                        query,
                        className,
                    },
                });
            }

            if (schedule) {
                const query = {
                    lightbox: true,
                    officialPdfUploadId: schedule.officialPdfUploadId,
                    studentEnrollmentId: student.studentEnrollmentId,
                };
                const className = 'nav-link Blue';
                items.push({
                    text: 'Student Schedule',
                    routerLink: {
                        name,
                        params,
                        query,
                        className,
                    },
                });
            }

            if (examSchedule) {
                const query = {
                    lightbox: true,
                    officialPdfUploadId: examSchedule.officialPdfUploadId,
                    studentEnrollmentId: student.studentEnrollmentId,
                };
                const className = 'nav-link Blue';
                items.push({
                    text: 'Exam Invitations',
                    routerLink: {
                        name,
                        params,
                        query,
                        className,
                    },
                });
            }

            if (transcript) {
                const query = {
                    lightbox: true,
                    officialPdfUploadId: transcript.officialPdfUploadId,
                    studentEnrollmentId: student.studentEnrollmentId,
                };
                const className = 'nav-link Blue';
                items.push({
                    text: 'Transcript',
                    routerLink: {
                        name,
                        params,
                        query,
                        className,
                    },
                });
            }
            if (!this.isHomeUser) {
                items.push({
                    text: 'Other Documents',
                    routerLink: null,
                });
            }
            return items;
        },
    },
    methods: {
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        showStudentBadgesRoute() {
            this.$router.push({
                name: 'StudentBadges',
                params: {
                    studentEnrollmentId: this.student.studentEnrollmentId,
                },
            });
        },
        toggleThumbnail() {
            if (!this.clickable) return;
            if (!this.clientSideEncryptionEnabled || !this.serverSideEncryptionEnabled) {
                this.thumbnail = !this.thumbnail;
            }
        },
        formatGradeLevel(gradeLevel) {
            return this.$_utilMixins_format_grade_level(gradeLevel);
        },
        setUploadFocused(focused) {
            this.uploadFocused = focused;
        },
    },
});

</script>

<style scoped src='../css/colors.scss' lang="scss" />

<style lang="scss" scoped>

.badge-container {
    line-height: 32px;
    height: 32px;
    overflow: hidden;
}

.kt-ribbon__target {
    border-radius: 5px 0 0 5px
}

.kt-ribbon__target.wide {
    right: -24px;
    top: 5px;
}

.wider > .kt-top-badge {
    top: -42px;
    right: -42px;
}
.kt-top-badge.narrow:hover {
    .narrow:hover {
        width: 200px;
    }
    .narrow:hover .badge-title {
        margin-left: 10px;
        opacity: 1;
        &.wide {
            width: 200px;
        }
    }
}
.kt-ribbon--border-dash-vert .kt-ribbon__target:after {
    border-left: 1px solid;
    border-right: none;
    border-top: none;
    border-bottom: none;
    border-left-style: dashed;
    border-right-style: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    right: 0;
}

.kt-widget__info {
    min-width: 180px;
}
.kt-top-badge {
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 3;
    font-size: 2rem;
    line-height: 1.5;
    text-align: center;
}
.kt-ribbon__target {
    width: 48px;
    white-space: nowrap;
    transition: 300ms linear;
    z-index: 3;
}

.narrow .badge-title {
    margin-left: 12px;
    transition: 300ms linear;
}

.kt-ribbon__target .badge-icon {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 1.3rem;
    margin-left: 4px;
    margin-top: -3px;
    position: absolute;
}

.ribbon-container {
    display: flex;
    align-items: left;
    overflow: hidden;
    justify-content: left;
    width: 100%;
    height: 100%;
}

.narrow:hover {
    width: 200px;
}
.narrow:hover .badge-title {
    margin-left: 10px;
    opacity: 1;
    &.wide {
        width: 200px;
    }
}

.wide  {
    width: auto;
    margin-left: 12px;
}

span.kt-widget__label {
    min-width: 110px;
}
a.kt-widget__data i {
    display: none;
}
a.kt-widget__data:hover i {
    display: inline-block;
}
div.kt-widget__media {
    width: 90px;
    height: 90px;
}
img.kt-widget__img {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: 90px;
    min-width: 68px;
}

img.big-picture {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: auto;
}
</style>
