<template>
<ul
    :class="[
        'nav nav-pills pt-2',
        wideMode ? '' : 'flex-column flex-sm-column'
    ]"
>
    <li
        v-for="item in externalDocuments"
        :key="item.text"
        class="nav-item"
    >
        <router-link
            v-if="item.routerLink"
            :to="item.routerLink"
            :class="`nav-link Blue`"
        >
            {{ item.text }}
        </router-link>
        <a
            v-else
            :class="`nav-link Blue`"
            @click.stop.prevent="showUploader = true"
        >
            {{ item.text }}
        </a>
    </li>
</ul>
</template>
<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    name: 'StudentExternalDocuments',
    components: {},
    props: {
        externalDocuments: {
            type: Array,
            required: true,
        },
        wideMode: {
            type: Boolean,
            default: false,
        },
    },
});

</script>
