var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { role: _vm.clickable ? "button" : "" },
      on: {
        click: function ($event) {
          return _vm.badgeClick(_vm.badge)
        },
      },
    },
    [
      _vm.badge
        ? _c(
            "vue-ellipse-progress",
            {
              attrs: {
                progress: _vm.progress,
                "legend-value": !_vm.earned ? 0 : undefined,
                dot: !_vm.earned ? 0 : undefined,
                determinate: !_vm.earned,
                color: _vm.badgeHexColor,
                "empty-color": "transparent",
                "empty-color-fill": _vm.colorFill,
                size: _vm.width,
                thickness: _vm.thickness,
                line: !_vm.earned ? "butt" : "round",
                "empty-thickness": 0,
                "line-mode": "out 5",
                legend: false,
                animation:
                  _vm.badge.badgeType == "Achievement Level"
                    ? "default 700 1300"
                    : "default 1000",
                loading: false,
                "no-data": _vm.badge.badgeType !== "Achievement Level",
              },
            },
            [
              _c(
                "div",
                {
                  class: `badge-container ${
                    _vm.isPortletRibbon ? "ribbon" : ""
                  } ${_vm.badgeSize}`,
                  style: {
                    width: `${_vm.width}px`,
                  },
                  attrs: { slot: "legend-caption" },
                  slot: "legend-caption",
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "badge-icon",
                        !_vm.earned ? "badge-icon-grey" : "",
                      ],
                    },
                    [_vm._v(" " + _vm._s(_vm.badge.badgeIcon) + " ")]
                  ),
                  false && !_vm.isPortletRibbon
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "kt-badge kt-badge--inline badge-title kt-badge--sm kt-badge--rounded px-3 py-2 m-0",
                          class: [
                            !_vm.earned
                              ? "kt-badge--grey"
                              : _vm.badge.badgeColor,
                          ],
                        },
                        [_vm._v(" " + _vm._s(_vm.badge.badgeTitle) + " ")]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }