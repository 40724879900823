<template>
<ul
    class="nav nav-pills nav-pills-sm nav-sm py-0 px-3 my-0"
    role="tablist"
>
    <small class="mx-3 mt-2">Marking Period</small>
    <li
        v-for="mp in visibleMarkingPeriods"
        :key="`mp_${mp.schoolTermMarkingPeriodId}`"
        class="nav-item"
    >
        <a
            class="nav-link"
            :class="{ active: mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId }"
            href="#"
            @click.stop.prevent="select(mp)"
        >
            {{ mp.markingPeriod }}
        </a>
    </li>
</ul>
</template>

<script lang="ts">

import Vue from 'vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'SubHeaderMarkingPeriodPicker',
    computed: {

        visibleMarkingPeriods() {
            const { markingPeriods, schoolTermMarkingPeriodId } = this;
            const visibleMarkingPeriods = [];
            if (!schoolTermMarkingPeriodId) return markingPeriods.slice(0, 3);

            const selectedIndex = markingPeriods.findIndex((mp) => mp.schoolTermMarkingPeriodId === schoolTermMarkingPeriodId);
            if (selectedIndex === -1) return markingPeriods.slice(0, 3);

            visibleMarkingPeriods.push({ ...markingPeriods[selectedIndex] });

            if (selectedIndex + 1 < markingPeriods.length) {
                visibleMarkingPeriods.push({ ...markingPeriods[selectedIndex + 1] });
            }
            if (selectedIndex - 1 >= 0 && visibleMarkingPeriods.length < 3) {
                visibleMarkingPeriods.unshift({ ...markingPeriods[selectedIndex - 1] });
            }

            if (visibleMarkingPeriods.length < 3) {
                if (selectedIndex + 2 < markingPeriods.length) {
                    visibleMarkingPeriods.push({ ...markingPeriods[selectedIndex + 2] });
                }
                if (selectedIndex - 2 >= 0 && visibleMarkingPeriods.length < 3) {
                    visibleMarkingPeriods.unshift({ ...markingPeriods[selectedIndex - 2] });
                }
            }

            return visibleMarkingPeriods;
        },
        markingPeriods() {
            return this.$store.state.database.markingPeriods
                .filter((x) => !x.deleted)
                .map((x) => ({ ...x }));
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
    },
    methods: {
        select(mp) {
            const { schoolTermMarkingPeriodId } = this;
            const { commit } = this.$store;
            if (schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId) {
                // commit(Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD, null);
                return;
            }
            commit(Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD, mp.schoolTermMarkingPeriodId);
        },
    },
});

</script>
