<template>
<div :role="clickable ? 'button': ''" @click="badgeClick(badge)">
    <vue-ellipse-progress
        v-if="badge"
        :progress="progress"
        :legend-value="!earned ? 0 : undefined"
        :dot="!earned ? 0 : undefined"
        :determinate="!earned"
        :color="badgeHexColor"
        empty-color="transparent"
        :empty-color-fill="colorFill"
        :size="width"
        :thickness="thickness"
        :line="!earned ? 'butt' : 'round'"
        :empty-thickness="0"
        line-mode="out 5"
        :legend="false"
        :animation="badge.badgeType == 'Achievement Level' ? 'default 700 1300' : 'default 1000'"
        :loading="false"
        :no-data="badge.badgeType !== 'Achievement Level'"
    >
        <div
            slot="legend-caption"
            :class="`badge-container ${isPortletRibbon ? 'ribbon' : ''} ${badgeSize}`"
            :style="{
                width: `${width}px`,
            }"
        >
            <div :class="['badge-icon', !earned ? 'badge-icon-grey' : '']">
                {{ badge.badgeIcon }}
            </div>
            <div
                v-if="false && !isPortletRibbon"
                :class="[
                    !earned ? 'kt-badge--grey' : badge.badgeColor,
                ]"
                class="kt-badge kt-badge--inline badge-title kt-badge--sm kt-badge--rounded px-3 py-2 m-0"
            >
                {{ badge.badgeTitle }}
            </div>
        </div>
    </vue-ellipse-progress>
</div>
</template>

<script>
import tinycolor from 'tinycolor2';
// @ts-ignore
import cssVars from '../css/colors.scss';

export default {
    name: 'AchievementBadge',
    props: {
        badge: {
            type: Object,
            required: true,
        },
        isPortletRibbon: {
            type: Boolean,
            default: false,
        },
        badgeSize: {
            type: String,
            default: 'md',
        },
        earned: {
            type: Boolean,
            default: false,
        },
        highlight: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        progress() {
            const { earned, highlight } = this;
            const { badgeType, badgeRequirements } = this.badge;
            if (!highlight) return 0;
            if (!earned) return null;

            if (badgeType == 'Achievement Level') {
                const { achievementLevel, totalAchievementLevels } = this.badge;
                if (earned) {
                    return Math.floor((achievementLevel / totalAchievementLevels) * 100);
                }
                return Math.floor(((achievementLevel - 1) / totalAchievementLevels) * 100);
            }

            if (badgeRequirements.length > 0) {
                return 100;
            }
            return null;
        },
        clickable() {
            const { earned, badge, isPortletRibbon } = this;
            if (this.$route.name === 'StudentBadges' && isPortletRibbon) return false;
            if (!badge) return false;
            if (isPortletRibbon) return true;
            const { achievementLevel } = badge;
            if (earned && achievementLevel !== 1) return true;
            return false;
        },
        thickness() {
            // disable progress bar return 0
            if (this.isPortletRibbon) return 0;
            if (this.badgeSize === 'xl') return 18;
            if (this.badgeSize === 'lg') return 16;
            if (this.badgeSize === 'md') return 6;
            if (this.badgeSize === 'sm') return 1;
            return 0;
        },
        width() {
            if (this.badgeSize === 'xl') return 256;
            if (this.badgeSize === 'lg') return 128;
            if (this.badgeSize === 'md') return 64;
            if (this.badgeSize === 'sm') return 32;
            return 0;
        },
        colorFill() {
            const { isPortletRibbon } = this;
            const base = {
                radial: false,
                colors: [
                    {
                        color: this.badgeHexColor,
                        offset: '0',
                        opacity: '0.6',
                    },
                ],
            };

            if (isPortletRibbon) {
                // base.radial = false;
                // base.colors = [
                //     {
                //         color: '#754fc1',
                //         offset: '0',
                //         opacity: '0.3',
                //     },
                //     {
                //         color: '#366bfc',
                //         offset: '100',
                //         opacity: '0.3',
                //     },
                // ];

                // base.colors = [
                //     {
                //         color: this.badgeLightHexColor,
                //         offset: '0',
                //         opacity: '0.2',
                //     },
                //     {
                //         color: this.badgeHexDarkestColor,
                //         offset: '100',
                //         opacity: '0.9',
                //     },
                // ];
            }

            return base;
        },
        badgeHexColor() {
            const { highlight } = this;
            const hexColor = highlight ? cssVars[this.badge.badgeColor] : '#CCCCCC';
            if (!this.earned) {
                const color = tinycolor(hexColor);
                return color.greyscale().setAlpha(0.3).toHex8String();
            }
            const color = tinycolor(hexColor);
            return color.brighten(10).toHexString();
        },
        badgeHexDarkColor() {
            const hexColor = cssVars[this.badge.badgeColor];
            if (!this.earned) {
                const color = tinycolor(hexColor);
                return color.greyscale().darken(10).toHexString();
            }
            const color = tinycolor(hexColor);
            return color.darken(10).toHexString();
        },
        complementColor() {
            const hexColor = cssVars[this.badge.badgeColor];
            const color = tinycolor(hexColor);
            return color.complement().setAlpha(0.8).toHex8String();
        },
        badgeHexDarkestColor() {
            const hexColor = cssVars[this.badge.badgeColor];
            const color = tinycolor(hexColor);
            return color.darken(15).toHexString();
        },
        badgeLightHexColor() {
            const hexColor = cssVars[this.badge.badgeColor];
            const color = tinycolor(hexColor);
            return color.lighten(50).toHexString();
        },
    },
    mounted() {
    },
    methods: {
        badgeClick(badge) {
            const { clickable, isPortletRibbon } = this;
            if (!clickable) return;
            if (!badge) return;
            if (isPortletRibbon) {
                this.$router.push({
                    name: 'StudentBadges',
                    params: {
                        studentEnrollmentId: this.$route.params.studentEnrollmentId,
                    },
                });
            } else {
                const { earned } = this;
                const { badgeColor, badgeIcon } = badge;
                const { achievementLevel, totalAchievementLevels } = badge;
                const hexColor = cssVars[badgeColor];
                if (!earned) return;
                if (achievementLevel != totalAchievementLevels) return;
                this.celebrate({
                    launchCannons: true,
                    cannonColor: hexColor,
                    lanchEmojiBurst: true,
                    emoji: badgeIcon,
                    emojiColor: hexColor,
                });
            }
        },
    },
};

</script>

<style lang="scss" scoped>

.badge-icon-grey {
    filter: grayscale(100%);
}
.kt-badge--grey {
    color: #fff;
    background: #fff;
}

div.badge-container {
    container-type: inline-size;
}
div.badge-title {
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flow;
    box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
}
/*
div.badge-container.xl {
    div.badge-icon {
        font-size: 8rem;
        padding: 0;
        height: 116px;
    }
    div.badge-title {
        font-size: 1.2rem;
        font-weight: 500;
        min-width: 140px;
        margin: 10px auto;
        line-height: 1.2rem;
    }
}

div.badge-container.lg {
    div.badge-icon {
        font-size: 4rem;
        height: 60px;
        margin-top: 20px;
    }
    div.badge-title {
        font-size: 0.8rem;
        font-weight: 500;
        min-width: 80px;
        margin: 10px auto;
        line-height: 1.2rem;
    }
}
*/

div.badge-container.md {
    &.ribbon {
        div.badge-icon {
            margin-top: 0px;
            font-size: 2.3rem
        }
    }

    div.badge-icon {
        font-size: 2rem;
        margin-top: 6px;
    }

    div.badge-title {
        display: block;
        font-size: 0.8rem;
        font-weight: 500;
        min-width: 40px;
        margin: 10px auto;
        line-height: 1.2rem;
    }
}

div.badge-container.lg {
    &.ribbon {
        div.badge-icon {
            margin-top: 0px;
            font-size: 4rem;
        }
    }
    div.badge-icon {
        font-size: 4rem;
        /* height: 60px;
        margin-top: 20px; */
    }
    div.badge-title {
        font-size: 0.8rem;
        font-weight: 500;
        min-width: 80px;
        margin: 10px auto;
        line-height: 1.2rem;
    }
}

</style>
