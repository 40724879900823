var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.student
    ? _c("div", [
        _c("div", { staticClass: "kt-portlet" }, [
          _c(
            "div",
            {
              class: [
                "kt-portlet__body kt-ribbon kt-ribbon--clip kt-ribbon--right",
                _vm.wideMode ? "p-4 my-3 wider" : "",
              ],
              attrs: { role: _vm.clickable ? "button" : "" },
            },
            [
              _vm.topBadge && _vm.showTopBadge
                ? [
                    _c(
                      "span",
                      { staticClass: "kt-top-badge" },
                      [
                        _c("AchievementBadge", {
                          attrs: {
                            badge: _vm.topBadge,
                            earned: true,
                            "is-portlet-ribbon": true,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: `kt-ribbon__target pl-0 ${
                          _vm.topBadge.badgeColor
                        } ${_vm.wideMode ? "wide" : "narrow"}`,
                      },
                      [
                        _c("span", { staticClass: "kt-ribbon__inner" }),
                        _c("div", { staticClass: "ribbon-container" }, [
                          _c(
                            "span",
                            {
                              class: `badge-title ${
                                _vm.wideMode ? "wide" : "narrow"
                              }`,
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.topBadge.badgeTitle) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                : _vm._e(),
              _c(
                "div",
                {
                  class: `kt-widget kt-widget--user-profile-2 kt-widget--${
                    _vm.deviceType
                  } kt-widget--${_vm.displayWide ? "wide" : "narrow"}`,
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { class: [_vm.wideMode ? "col-6" : "col-12"] }, [
                      !_vm.thumbnail
                        ? _c(
                            "div",
                            { staticClass: "kt-widget__head mt-0 pt-0" },
                            [
                              _c("StudentPortfolioAvatar", {
                                attrs: { student: _vm.student },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.toggleThumbnail.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              }),
                              _c("div", { staticClass: "kt-widget__info" }, [
                                (_vm.encryptionEnabled &&
                                  _vm.$_userMixins_isSchoolUser) ||
                                _vm.isHomeUser
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "kt-widget__username d-block w-100",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.$_panelMixins_openPanelForStudent(
                                              _vm.student
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.student.maskedStudentName
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass:
                                          "kt-widget__username d-block w-100",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.$_panelMixins_openPanelForStudent(
                                              _vm.student
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.student.lastName) +
                                            ", " +
                                            _vm._s(_vm.student.firstName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                _c("span", { staticClass: "kt-widget__desc" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$_utilMixins_format_grade_level(
                                          _vm.student.gradeLevel
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "pull-right" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.student.homeRoom) + " "
                                    ),
                                  ]),
                                ]),
                                !_vm.isHomeUser
                                  ? _c(
                                      "div",
                                      { staticClass: "badge-container" },
                                      [
                                        _vm.administrativeBadges.length
                                          ? [
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v(" Badges: ")]
                                              ),
                                              _vm._l(
                                                _vm.administrativeBadges,
                                                function (item) {
                                                  return _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-tooltip",
                                                          rawName:
                                                            "v-b-tooltip.hover",
                                                          modifiers: {
                                                            hover: true,
                                                          },
                                                        },
                                                      ],
                                                      key: `badge_${item.badge.badgeId}`,
                                                      staticClass: "mx-1",
                                                      attrs: {
                                                        role: "button",
                                                        title: `${item.count}x ${item.badge.badgeTitle}`,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.showStudentBadgesRoute.apply(
                                                            null,
                                                            arguments
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.badge.badgeIcon
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        : _c("div", { attrs: { role: "button" } }, [
                            _c(
                              "div",
                              { staticClass: "w-100 text-center" },
                              [
                                _vm.student.pictureGuid
                                  ? _c("b-img", {
                                      staticClass: "big-picture",
                                      attrs: {
                                        src: `https://lilo-app.s3.amazonaws.com/${_vm.student.pictureGuid}.jpg`,
                                        "fluid-grow": "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          _vm.thumbnail = !_vm.thumbnail
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "kt-widget__head mt-4" }, [
                              _c("div", { staticClass: "kt-widget__info" }, [
                                _vm.encryptionEnabled
                                  ? _c(
                                      "span",
                                      { staticClass: "kt-widget__username" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.student.maskedStudentName
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      { staticClass: "kt-widget__username" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.student.lastName) +
                                            ", " +
                                            _vm._s(_vm.student.firstName) +
                                            " "
                                        ),
                                      ]
                                    ),
                                _c("span", { staticClass: "kt-widget__desc" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$_utilMixins_format_grade_level(
                                          _vm.student.gradeLevel
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "pull-right" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.student.homeRoom) + " "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                    ]),
                    _c("div", { class: [_vm.wideMode ? "col-6" : "d-none"] }, [
                      !_vm.isHomeUser
                        ? _c("div", { staticClass: "text-center pt-4" }, [
                            _vm.encryptionEnabled
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-label-brand btn-bold btn-upper mt-4",
                                    staticStyle: { "min-width": "160px" },
                                    attrs: { type: "button" },
                                    on: { click: _vm.decrypt },
                                  },
                                  [_vm._v(" Unlock PII ")]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-widget__body" }, [
                    !_vm.encryptionEnabled || _vm.isHomeUser
                      ? _c(
                          "div",
                          {
                            staticClass: "kt-widget__item",
                            staticStyle: { "font-size": "1.1rem" },
                          },
                          [
                            !_vm.isHomeUser
                              ? [
                                  _vm.student.address && _vm.student.street
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget__contact pt-4 pb-3",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "kt-widget__label" },
                                            [_vm._v(" Address ")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "kt-widget__data text-right",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.student.street) +
                                                  " "
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.student.city) +
                                                  ", " +
                                                  _vm._s(_vm.student.state) +
                                                  " " +
                                                  _vm._s(_vm.student.zip) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget__contact pb-3" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "kt-widget__label" },
                                        [_vm._v("Student Id")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "kt-widget__data" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.student.extStudentId.substring(
                                                  0,
                                                  3
                                                )
                                              ) +
                                              "-" +
                                              _vm._s(
                                                _vm.student.extStudentId.substring(
                                                  3,
                                                  6
                                                )
                                              ) +
                                              "-" +
                                              _vm._s(
                                                _vm.student.extStudentId.substring(
                                                  6,
                                                  9
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm.student.googleEmail
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget__contact pb-3",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "kt-widget__label" },
                                            [_vm._v("Google")]
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "kt-widget__data text-right",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                `${_vm.$_utilMixins_format_email(
                                                  _vm.student.googleEmail
                                                )}`
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.student.schoolEmail
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-widget__contact pb-3",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "kt-widget__label" },
                                            [_vm._v("Email")]
                                          ),
                                          _c("span", {
                                            staticClass:
                                              "kt-widget__data text-right",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                `${_vm.$_utilMixins_format_email(
                                                  _vm.student.schoolEmail
                                                )}`
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _c("div", {
                              staticClass:
                                "kt-separator kt-separator--space-lg kt-separator--border-dashed",
                            }),
                            _c("StudentExternalDocuments", {
                              attrs: {
                                "external-documents": _vm.externalDocuments,
                                "wide-mode": _vm.wideMode,
                              },
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                  !_vm.wideMode && !_vm.isHomeUser
                    ? _c("div", { staticClass: "kt-widget__footer" }, [
                        _vm.encryptionEnabled
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-label-primary btn-lg kt-font-lg btn-upper",
                                attrs: { type: "button" },
                                on: { click: _vm.decrypt },
                              },
                              [_vm._v(" UNLOCK PII ")]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            2
          ),
        ]),
        _vm.showUploader
          ? _c("div", { staticClass: "kt-portlet" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "kt-portlet__body" },
                [
                  _c("StudentProfileDocuments", {
                    attrs: {
                      "wide-mode": _vm.wideMode,
                      "set-upload-focused": _vm.setUploadFocused,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Documents "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }