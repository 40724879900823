const badgeMixins = {
    computed: {
        $_badgeMixins_getStudentBadgeFromRoute() {
            const { studentEnrollmentId } = this.$route.params;
            const studentBadge = getStudentBadge(studentEnrollmentId, this.$store);
            return studentBadge;
        },
        $_badgeMixins_getAchievementLevelFromRoute() {
            const { studentEnrollmentId } = this.$route.params;
            const studentBadge = getStudentBadge(studentEnrollmentId, this.$store);
            return studentBadge ? studentBadge.achievementBadge : null;
        },
        $_badgeMixins_getStickyBadgeFromRoute() {
            const { studentEnrollmentId } = this.$route.params;
            const studentBadge = getStudentBadge(studentEnrollmentId, this.$store);
            return studentBadge ? studentBadge.stickyBadge : null;
        },
        $_badgeMixins_getEarnedBadgesFromRoute() {
            const { studentEnrollmentId } = this.$route.params;
            const studentBadge = getStudentBadge(studentEnrollmentId, this.$store);
            return studentBadge ? studentBadge.earnedBadges : [];
        },
        $_badgeMixins_getUniqueEarnedBadgesFromRoute() {
            const { studentEnrollmentId } = this.$route.params;
            const studentBadge = getStudentBadge(studentEnrollmentId, this.$store);
            const earnedBadges = studentBadge ? studentBadge.earnedBadges : [];
            return getUniqueEarnedBadges(earnedBadges);
        },
        $_badgeMixins_getAchievementLevels() {
            return getAchievementLevels(this.$store);
        },
    },
};

function getUniqueEarnedBadges(earnedBadges) {
    const unique = new Map();
    earnedBadges.forEach((b) => { // label keys for previously cached grades
        const key = `${b.badgeId}`;
        if (unique.has(key)) {
            const { count } = unique.get(key);
            unique.set(key, {
                ...unique.get(key),
                count: count + 1,
            });
            return;
        }
        unique.set(key, {
            badge: { ...b },
            count: 1,
        });
    });

    const uniqueEarnedBadges = [...unique.values()];
    uniqueEarnedBadges.sort((a, b) => {
        // lowest to highest
        if (a.badge.badgeRank < b.badge.badgeRank) return 1;
        if (a.badge.badgeRank > b.badge.badgeRank) return -1;
        return 0;
    });

    return uniqueEarnedBadges;
}

function getStudentBadge(studentEnrollmentId, $store) {
    if (!studentEnrollmentId) return null;
    const { database } = $store.state;
    const studentBadge = database.studentBadges.find((s) => s.studentEnrollmentId == studentEnrollmentId) || null;
    return studentBadge || null;
}

function getAchievementLevels($store) {
    const { badges } = $store.state.database;
    const achievementLevels = badges
        .map((b) => ({ ...b }))
        .filter((b) => b.badgeType === 'Achievement Level')
        .sort((a, b) => a.badgeRank > b.badgeRank ? 1 : -1); // lowest to highest
    return achievementLevels;
}

export {
    badgeMixins as default,
    getStudentBadge,
    getUniqueEarnedBadges,
};
