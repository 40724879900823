<!-- eslint-disable vue/no-v-html -->
<template>
<div class="pt-4">
    <div
        v-if="loading"
        class="mt-1 d-flex justify-content-center loader"
    >
        <PortletLoader :inline="true" />
    </div>
    <template v-else>
        <div v-if="documents.length > 0">
            <b-dropdown
                v-for="(document, idx) in documents"
                :key="`document_${document.studentProfileDocumentId}_${idx}`"
                :class="!wideMode ? 'student-doc w-100 btn-block' : 'student-doc'"
                :toggle-class="'btn-clean btn-clean-primary'"
                variant="none"
                no-caret
            >
                <template #button-content>
                    <SVGIcon
                        :name="document.type"
                        class="kt-svg-icon pull-right"
                    />
                    <span class="pull-left">
                        {{ document.fileTitle }}
                    </span>
                </template>
                <b-dropdown-item
                    variant="none"
                    button-class="dropdown-item"
                    target="_blank"
                    :href="downloadDocument(document)"
                >
                    <i class="la la-download" />
                    Download
                </b-dropdown-item>
                <b-dropdown-item
                    v-if="$_userMixins_isSchoolUser && !$store.state.forceStudentView"
                    variant="danger"
                    button-class="dropdown-item"
                    @click="deleteDocument(document)"
                >
                    <i class="la la-trash" />
                    Delete
                </b-dropdown-item>
            </b-dropdown>
        </div>

        <div
            v-if="$_userMixins_isSchoolUser && !$store.state.forceStudentView && !addingDocument"
            :class="documents.length ? 'pt-4' : 'pt-2'"
        >
            <a
                href="#"
                class="kt-font-lg kt-font-bolder kt-font-success"
                @click.stop.prevent="addingDocument = true"
            >
                <i class="fa fa-plus" />
                Upload New Document
            </a>
        </div>
        <div
            v-else
            class="pt-2"
        >
            <div class="py-1 pb-3">
                <input
                    id="file-input"
                    type="file"
                    name="file"
                    style="display: none"
                    accept=".pdf,.xls,.xlsx"
                    @change="onFileBrowseSelect"
                >
                <label>Document File</label>
                <div
                    class="input-group"
                    style="cursor: pointer"
                    @click.stop.prevent="triggerFileBrowser"
                >
                    <input
                        type="text"
                        class="form-control"
                        :placeholder="form.file ? `${form.file.name}` : '.pdf, .xls, or .xlsx'"
                        readonly="readonly"
                        @focus="focusInput"
                        @blur="unFocusInput"
                    >
                    <div class="input-group-append">
                        <span class="input-group-text">
                            Browse
                        </span>
                    </div>
                </div>
            </div>
            <div class="pb-3">
                <label>Document name</label>
                <input
                    v-model="form.documentName"
                    type="text"
                    class="form-control"
                    placeholder="Document name"
                    @focus="focusInput"
                    @blur="unFocusInput"
                >
            </div>
            <div class="kt-form__actions pt-3 pb-2">
                <div class="row">
                    <div class="col-lg-6" />
                    <div class="col-lg-6 kt-align-right">
                        <button
                            class="btn btn-success"
                            :disabled="uploadDisabled"
                            @click.stop.prevent="uploadDocument()"
                        >
                            Upload Document
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </template>
</div>
</template>

<script>
import Vue from 'vue';
import async from 'async';
import studentMixins from '../store/mixins/studentMixins';
import userMixins from '../store/mixins/userMixins';
import utilMixins from '../store/mixins/utilMixins';
import PortletLoader from './PortletLoader.vue';
import * as util from '../lib/uploads/util';
import * as network from '../network';
import SVGIcon from './SVGIcon/SVGIcon.vue';

export default Vue.extend({
    name: 'StudentProfileDocuments',
    components: {
        PortletLoader,
        SVGIcon,
    },
    mixins: [studentMixins, utilMixins, userMixins],
    props: {
        wideMode: {
            type: Boolean,
            required: true,
        },
        setUploadFocused: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            documents: [],
            addingDocument: false,
            timeout: null,
            loading: true,
            downloadPath: '',
            form: {
                documentName: '',
                file: null,
            },
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        uploadDisabled() {
            const v = this;
            const {
                file, documentName,
            } = v.form;
            return !file && !documentName;
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        populate() {
            const v = this;
            v.addingDocument = false;
            const { studentId } = v.student;
            const { user, showError } = v;
            const { schoolTermId, schoolId } = user.school;
            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    studentId,
                },
            };
            network.studentProfileDocument.getStudentProfileDocuments(params, (err, res) => {
                if (err) showError(err);
                v.documents = (res || []).map((d) => {
                    const doc = d;
                    doc.type = 'doc';
                    // split bucket path on last instance of '.'
                    const ext = doc.bucketPath.split('.').pop();
                    if (ext == 'pdf') doc.type = 'pdf';
                    if (ext == 'xls' || ext == 'xlsx') doc.type = 'xls';
                    if (ext == 'jpg' || ext == 'jpeg' || ext == 'png') doc.type = 'jpg';
                    return doc;
                });
                v.loading = false;
            });
        },
        onFileBrowseSelect(e) {
            [this.form.file] = e.target.files;
        },
        triggerFileBrowser() {
            document.getElementById('file-input').click();
        },
        uploadDocument() {
            const v = this;
            const {
                user,
                showError,
            } = v;
            const { documentName, file } = v.form;
            const { schoolId, schoolTermId } = user.school;
            const { studentId } = v.student;

            if (!file) return;

            const validFileTypes = [
                'application/pdf',
                'application/excel',
                'application/vnd.ms-excel',
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ];
            if (validFileTypes.indexOf(file.type) < 0) {
                showError('Invalid document type. Valid types are pdf, xls and xlsx');
                return;
            }

            v.loading = true;

            async.auto({
                uploadFile(next) {
                    util.uploadToAWS('student-profile-documents', file, user, (err, results) => {
                        if (err) return util.uploadError(file, user, next);
                        return next(err, results);
                    });
                },
                savePdf: ['uploadFile', (results, next) => {
                    const { key } = results.uploadFile.parameters.fields;
                    if (!key) return next(new Error('PDF Upload Failed'));

                    const params = {
                        url: { schoolId, schoolTermId, studentId },
                        body: {
                            studentId,
                            schoolTermId,
                            fileTitle: documentName,
                            bucketPath: key,
                        },
                    };
                    network.studentProfileDocument.addStudentProfileDocument(params, next);
                }],
            }, 5, (err) => {
                if (err) showError('The upload failed, please try again!');
                else {
                    v.form.documentName = '';
                    v.form.file = null;
                    v.populate();
                }
                v.loading = false;
            });
        },
        deleteDocument(document) {
            const v = this;
            const { studentId } = v.student;
            const { user, showError } = v;
            const { schoolTermId, schoolId } = user.school;
            const { studentProfileDocumentId } = document;
            const params = {
                url: {
                    schoolTermId,
                    schoolId,
                    studentId,
                },
                body: { studentProfileDocumentId },
            };
            network.studentProfileDocument.deleteStudentProfileDocument(params, (err) => {
                v.loading = false;
                if (err) return showError('Unable to delete document');
                v.documents = v.documents
                    .slice()
                    .filter((d) => d.studentProfileDocumentId != studentProfileDocumentId);
            });
        },
        downloadDocument(document) {
            const v = this;
            const { studentId } = v.student;
            const { user } = v;
            const { schoolTermId, schoolId } = user.school;
            const { studentProfileDocumentId } = document;
            return `/api/schools/${schoolId}/terms/${schoolTermId}/student/${studentId}/document/${studentProfileDocumentId}/download`;
        },
        focusInput() {
            this.setUploadFocused(true);
        },
        unFocusInput() {
            this.setUploadFocused(false);
        },
    },
});

</script>

<style scoped>
.loader .btn {
  padding: 0.5rem 1rem 0.5rem 3rem !important;
}

.delete-button  {
    width: auto !important;
    padding: .5rem !important;
}
.student-doc {
    min-width: 200px;
}
</style>
