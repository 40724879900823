var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    {
      staticClass: "nav nav-pills nav-pills-sm nav-sm py-0 px-3 my-0",
      attrs: { role: "tablist" },
    },
    [
      _c("small", { staticClass: "mx-3 mt-2" }, [_vm._v("Marking Period")]),
      _vm._l(_vm.visibleMarkingPeriods, function (mp) {
        return _c(
          "li",
          {
            key: `mp_${mp.schoolTermMarkingPeriodId}`,
            staticClass: "nav-item",
          },
          [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: {
                  active:
                    mp.schoolTermMarkingPeriodId ==
                    _vm.schoolTermMarkingPeriodId,
                },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.select(mp)
                  },
                },
              },
              [_vm._v(" " + _vm._s(mp.markingPeriod) + " ")]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }