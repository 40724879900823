var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    {
      class: [
        "nav nav-pills pt-2",
        _vm.wideMode ? "" : "flex-column flex-sm-column",
      ],
    },
    _vm._l(_vm.externalDocuments, function (item) {
      return _c(
        "li",
        { key: item.text, staticClass: "nav-item" },
        [
          item.routerLink
            ? _c(
                "router-link",
                { class: `nav-link Blue`, attrs: { to: item.routerLink } },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              )
            : _c(
                "a",
                {
                  class: `nav-link Blue`,
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.showUploader = true
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.text) + " ")]
              ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }